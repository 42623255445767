<template>
    <div>
        <div class="mb-3">
            <h5><i class="el-icon-box text-primary mr-2"></i><i>Review the ports that can be opened (Checked if allowed)</i></h5>
        </div>
        <el-checkbox-group v-model="checkedPort" @change="handleCheckedPortChange">
            <el-checkbox border v-for="port in element.serviceDetails.split(',')" :label="port" :key="port"
                         :disabled="!enabledInput">
                <span style="font-weight: normal !important;">Port {{port}}</span>
            </el-checkbox>
        </el-checkbox-group>
        <div class="mt-2">
            <el-checkbox v-model="enabledInput"><span style="font-weight: normal;">Allow modify value</span></el-checkbox>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            element: {
                type: Object,
                default() {
                    return null;
                },
            },
            value: {
                type: String,
                default() {
                    return [];
                },
            },
        },
        watch: {
            element() {
                this.checkedPort = this.element.serviceDetails.split(',');
                this.$emit('input', this.checkedPort.join(','));
            },
            checkedPort(){
                this.$emit('input', this.checkedPort.join(','));
            },
        },
        data() {
            return {
                checkAll: false,
                checkedPort: [],
                enabledInput : false,
            };
        },
        created() {
            this.checkedPort = this.element.serviceDetails.split(',');
        },
        methods: {
            handleCheckedPortChange() {                
                if (this.checkedPort.length > 0) this.$emit('input', this.checkedPort.join(','));
                else this.$emit('input', null);        
            }
        }
    };
</script>